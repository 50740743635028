import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { IcoCanc, IcoEdit, Lens } from '@icons';
import { deleteScenario, downloadOEScenarioItem, cloneScenario } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import { Trans, propTrans } from '@lib/i18n/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faFileDownload } from '@fortawesome/free-solid-svg-icons';

const ActionBtnRendererNewScenarioTable = ({ data, setRefresh, setSbCloneDownloadShow }) => {
  const id = data.id;
  const [scenario, setScenario] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const shouldBeDisabled =
    parseInt(JSON.parse(localStorage.getItem('user'))['role']) !== 1 &&
    parseInt(JSON.parse(localStorage.getItem('user'))['idUser']) !== scenario?.userIdNumber;

  const isRegisteredUser = JSON.parse(localStorage.getItem('user'))['role'] == 5;

  useEffect(() => {
    setScenario(data);
  }, [data]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };
  const handleCloneDownloadStatusBar = (status, message, timeout) => {
    setSbCloneDownloadShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const handleDelete = async () => {
    const deleteResult = await deleteScenario(id);
    //console.log('deleteResult', deleteResult);

    if (deleteResult.status) {
      handleStatusBar('success', propTrans({ prop: 'A0599' }), true);

      setTimeout(() => {
        setSbShow(<></>);
        setRefresh(true);
        setShowModalDelete(false);
      }, 2000);
    } else {
      handleStatusBar('error', propTrans({ prop: 'A0600' }), false);
    }
  };

  const handleClone = async (id) => {
    const cloneResult = await cloneScenario(scenario, id);
    if (cloneResult.status) {
      handleCloneDownloadStatusBar('success', propTrans({ prop: 'A0730' }), true);

      setTimeout(() => {
        setRefresh(true);
        setSbCloneDownloadShow(<></>);
      }, 3000);
    } else {
      handleCloneDownloadStatusBar('error', propTrans({ prop: 'A0729' }), true);
    }
  };
  console.log('valerio', scenario);
  const handleDownload = async () => {
    handleCloneDownloadStatusBar('elaborating', false, true);
    const downloadResponse = await downloadOEScenarioItem(id, scenario.lastExecutedVariantId);
    console.log('DOWNLOAD:', downloadResponse);
    if (downloadResponse.ok === true) {
      handleCloneDownloadStatusBar('success', <Trans code='A0657' />, true);
      const blob = await downloadResponse.blob();

      const file = new File([blob], id);

      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = `Externalytics - ${data.name}.xlsx`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      setTimeout(() => {
        setSbCloneDownloadShow(<></>);
      }, 5000);
    } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
      handleCloneDownloadStatusBar('error', `${downloadResponse.statusText}`, false);
      const res = await downloadResponse.json();
      if (res.message) handleCloneDownloadStatusBar('error', `${res.message}`, false);
    }
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00055' />
    </Tooltip>
  );
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00056' />
    </Tooltip>
  );
  const excelTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0728' />
    </Tooltip>
  );
  const cloneTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00057' />
    </Tooltip>
  );

  return (
    <>
      {!isRegisteredUser && (
        <>
          <OverlayTrigger placement='top' overlay={editTooltip}>
            <Button
              disabled={shouldBeDisabled}
              variant='link'
              className='mr-2'
              href={'/scenariowizard/' + id}>
              <IcoEdit className='second-color ico-small edit' />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement='top' overlay={deleteTooltip}>
            <Button
              disabled={shouldBeDisabled}
              variant='link'
              className='mr-2'
              onClick={() => handleShowModal(setShowModalDelete)}>
              <IcoCanc className='second-color ico-small cancel' />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement='top' overlay={cloneTooltip}>
            <Button variant='link' onClick={() => handleClone(id)} className='mr-2'>
              <FontAwesomeIcon
                icon={faCopy}
                className='bluicon ico-small new-scenarios-table-icons'
              />
            </Button>
          </OverlayTrigger>
        </>
      )}
      <OverlayTrigger placement='top' overlay={excelTooltip}>
        <Button
          onClick={() => handleDownload(id)}
          variant='link'
          disabled={!scenario.lastExecutedVariantId}
          className='mr-2'>
          <FontAwesomeIcon
            icon={faFileDownload}
            className='bluicon ico-small new-scenarios-table-icons'
          />
        </Button>
      </OverlayTrigger>

      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0601' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionBtnRendererNewScenarioTable;
