import { useEffect, useState } from 'react';
import { propTrans, Trans } from '@lib/i18n/index';
import { Button, ButtonToolbar, Spinner } from 'react-bootstrap';
import { fetchData } from '@lib/helpers/index';
// import { useHistory } from 'react-router-dom';

import { IcoPlus } from 'icone/index';

// import CorporateEsgAnalysesTableButton from './CorporateEsgAnalysesComponents/CorporateEsgAnalysesTableButton';
import StatusBar from 'components/wizard/StatusBar';

import { getCorporateEsgAnalysis, getOrganizations } from '@lib/api/index';
import CorporateEsgAnalysesCompanyWrapper from './CorporateEsgAnalysesComponents/CorporateEsgAnalysesCompanyWrapper';
import CorporateEsgAnalysesCompanySelectorWrapper from './CorporateEsgAnalysesComponents/CorporateEsgAnalysesCompanySelectorWrapper';
import CustomModal from 'components/CustomModal/CustomModal';
import CorporateEsgAnalysesClassWrapper from './CorporateEsgAnalysesComponents/CorporateEsgAnalysesClassWrapper';

const CorporateEsgAnalyses = () => {
  const userCompanyId = JSON.parse(localStorage.getItem('user')).idOrganization || null;
  const [analyses, setAnalyses] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [childCompanies, setChildCompanies] = useState([]);
  const [companiesDef, setCompaniesDef] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!userCompanyId) return;
    fetchData(
      getCorporateEsgAnalysis,
      null,
      transformAnalysesData,
      [userCompanyId],
      null,
      setError,
      setIsLoading
    );
    fetchData(getOrganizations, setCompaniesDef, null, [], null, setError, setIsLoading);
  }, [userCompanyId]);
  useEffect(() => {
    if (refresh)
      fetchData(
        getCorporateEsgAnalysis,
        null,
        transformAnalysesData,
        [userCompanyId],
        null,
        setError,
        setIsLoading
      );
    setRefresh(false);
  }, [refresh, userCompanyId]);
  const transformAnalysesData = (data) => {
    setAnalyses(customSort(data, userCompanyId));
  };

  function customSort(array, userCompanyId) {
    return array.sort((a, b) => {
      // Check if either of the elements have the companyId equal to userCompanyId
      if (a.companyId === userCompanyId && b.companyId === userCompanyId) {
        // Both elements have companyId equal to userCompanyId, sort by id in descending order
        return b.id - a.id;
      } else if (a.companyId === userCompanyId) {
        // a has companyId equal to userCompanyId, so a should come before b
        return -1;
      } else if (b.companyId === userCompanyId) {
        // b has companyId equal to userCompanyId, so b should come before a
        return 1;
      } else {
        // Neither a nor b have companyId equal to userCompanyId, sort by companyId in ascending order
        if (a.companyId === b.companyId) {
          // If companyIds are the same, sort by id in descending order
          return b.id - a.id;
        } else {
          // Otherwise, sort by companyId in ascending order
          return a.companyId - b.companyId;
        }
      }
    });
  }

  useEffect(() => {
    if (!analyses) return;
    //create a Set of all unique the companyId inside analyses array
    const companies = new Set(
      analyses
        .filter((el) => {
          if (userCompanyId === 1) {
            return el;
          } else {
            return el.companyDependsOn === userCompanyId || el.companyId === userCompanyId;
          }
        })
        .map((el) => el.companyId)
    );
    setCompanies([...companies]);
  }, [analyses]);

  useEffect(() => {
    if (!selectedCompany) return;
    const childCompaniesSet = new Set(
      analyses
        .filter((el) => el.companyId === selectedCompany || el.companyDependsOn === selectedCompany)
        .map((el) => el.companyId)
    );
    setChildCompanies([...childCompaniesSet]);
  }, [selectedCompany]);

  return (
    <div>
      <h1 className='mb-4 pb-0'>
        <Trans code='A0851' />
      </h1>

      <div className='row'>
        <div className='col-12 text-right'>
          <ButtonToolbar className='justify-content-end my-2'>
            <Button variant='info add-user' onClick={() => setShow(true)} disabled={isLoading}>
              <div className='row align-items-center py-1 px-3'>
                {isLoading ? (
                  <Spinner animation='border' className='mr-2' size='sm' />
                ) : (
                  <IcoPlus className='light-color ico-small plus mr-2' />
                )}
                <span className='d-md-inline'>
                  <Trans code='A0852' />
                </span>
              </div>
            </Button>
          </ButtonToolbar>
        </div>
      </div>
      <div>
        {!!analyses.length && !analyses.some((el) => el.companyId === userCompanyId) && (
          <div className='simple-box py-5 mb-5'>
            <h1>
              <Trans code='A0865' />
            </h1>
          </div>
        )}
        {analyses &&
          companies &&
          companies
            .filter((el) => el === userCompanyId)
            .map((company) => {
              const analysesPerCompany = analyses.filter((el) => el.companyId === company);
              return (
                <CorporateEsgAnalysesCompanyWrapper
                  key={company}
                  company={company}
                  data={analysesPerCompany}
                  userCompanyId={userCompanyId}
                  dependingCompany={
                    companiesDef.find((el) => el.id === analysesPerCompany?.[0]?.companyDependsOn)
                      ?.name
                  }
                  setRefresh={setRefresh}
                />
              );
            })}

        {companies && companies.some((el) => el !== userCompanyId) && (
          <CorporateEsgAnalysesCompanySelectorWrapper
            data={companies.filter((el) => el !== userCompanyId)}
            companiesDef={companiesDef}
            setSelectedCompany={setSelectedCompany}
            selectedCompany={selectedCompany}
          />
        )}

        {selectedCompany &&
          analyses &&
          companies &&
          childCompanies.map((company) => {
            const analysesPerCompany = analyses.filter((el) => el.companyId === company);
            return (
              <CorporateEsgAnalysesCompanyWrapper
                key={company}
                company={company}
                data={analysesPerCompany}
                userCompanyId={userCompanyId}
                dependingCompany={
                  companiesDef.find((el) => el.id === analysesPerCompany?.[0]?.companyDependsOn)
                    ?.name
                }
                setRefresh={setRefresh}
              />
            );
          })}
        <CustomModal
          show={show}
          handleClose={() => setShow(false)}
          title={propTrans({ prop: 'A0875' })}
          content={<CorporateEsgAnalysesClassWrapper companyId={userCompanyId} />}
        />
      </div>
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};
export default CorporateEsgAnalyses;
